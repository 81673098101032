import React from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import LogoutButton from "./logout";
import Logo from "../assets/Logo.png";
import Checkout from "./Checkout";
import VideoTutorial from "./VideoTutorial";
import { useState } from "react";
import { Modal, Button } from "react-bootstrap";

const Navbar = (props) => {
  const [show, setShow] = useState(false);
  const [video, setVideo] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const fetchVideoURL = () => {
    fetch(
      "https://raw.githubusercontent.com/heyproping/proping-assets/main/assets/config.json"
    )
      .then((response) => response.json())
      .then((data) => {
        setVideo(data.videoURL);
        // Use the videoURL as needed
      })
      .catch((error) => {
        console.log(error);
      });
  };

  fetchVideoURL();

  return (
    <nav className="navbar navbar-light bg-light  border-bottom">
      <div className="container pt-1">
        <Link className="navbar-brand" to="/">
          {/* enter link click to home */}
          {/* <svg width="48" height="48" viewBox="0 0 200 200" fill="none" id="">
            <path
              d="M26.3044 157.511C34.1255 143.965 51.4475 139.323 64.9941 147.144C78.5406 154.965 83.182 172.287 75.3609 185.834C67.5398 199.38 50.2179 204.022 36.6713 196.201C23.1247 188.38 18.4833 171.058 26.3044 157.511Z"
              fill="#7B58CF"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M197.461 87.6439C198.533 85.7869 198.533 83.4989 197.46 81.6422L152.039 3.02854C150.967 1.17366 148.988 0.0308896 146.845 0.0302029L56.0533 0.00109894C53.9091 0.000411579 51.9274 1.1441 50.8553 3.0011L3.75558 84.5801L52.248 112.577L76.5016 70.5688C84.3227 57.0222 101.645 52.3808 115.191 60.202C128.738 68.0231 133.379 85.345 125.558 98.8916L101.304 140.9L150.361 169.223L197.461 87.6439Z"
              fill="#443894"
            />
            <path
              d="M3.75558 84.5801C11.4868 71.1893 28.6097 66.6004 42.0006 74.3316C55.3914 82.0628 59.9792 99.1864 52.248 112.577C44.5168 125.968 27.3942 130.555 14.0033 122.824C0.612469 115.093 -3.97565 97.971 3.75558 84.5801Z"
              fill="#7B58CF"
            />
            <path
              d="M101.468 140.619C109.289 127.072 126.611 122.431 140.157 130.252C153.704 138.073 158.345 155.395 150.524 168.941C142.703 182.488 125.381 187.129 111.834 179.308C98.2878 171.487 93.6464 154.165 101.468 140.619Z"
              fill="#7B58CF"
            />
          </svg> */}
          <div className="d-flex gap-2 ">
            <img src={Logo} height="28" />
            <small className="text-muted pt-02">beta</small>
          </div>
        </Link>

        {/* Support section */}
        <div className="d-flex align-items-center">
          <Dropdown align="end">
            <Dropdown.Toggle
              variant="light"
              title="Dropdown end"
              id="dropdown-menu-align-end"
              className="text-primary"
            >
              Support
              <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Body>
                  <div className="m-2">
                    <iframe
                      src={video}
                      frameBorder="0"
                      webkitallowfullscreen
                      mozallowfullscreen
                      allowFullScreen
                      style={{
                        position: "relative",
                        width: "100%",
                        height: "500px",
                      }}
                    ></iframe>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </Dropdown.Toggle>

            <Dropdown.Menu className="shadow-sm">
              <Dropdown.Item href="mailTo:heyproping@gmail.com">
                Contact Us
              </Dropdown.Item>
              <Dropdown.Item href="/faq">FAQ</Dropdown.Item>
              <Dropdown.Item onClick={handleShow}>Watch Demo</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown align="end">
            <Dropdown.Toggle
              variant="light"
              title="Dropdown end"
              id="dropdown-menu-align-end"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.5rem"
                height="1.5rem"
                fill="currentColor"
                className="bi bi-person bi-primary"
                viewBox="0 0 16 16"
              >
                <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
              </svg>
            </Dropdown.Toggle>

            <Dropdown.Menu className="shadow-sm">
              {!props.isSubscribed && (
                <div>
                  {/* <Dropdown.Item href="#/action-1">
                    <div className="d-grid">
                      <Checkout
                        email={props.email}
                        name="Upgrade"
                        customerId={props.customerId}
                      />
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Divider /> */}
                </div>
              )}

              <Dropdown.Item href="/settings">Settings</Dropdown.Item>
              <Dropdown.Divider />
              {/* Link to the log out button in Auth0 */}
              <LogoutButton />
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
