import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
// import './PriceSlider.css'; // Optional: for additional custom styles

const PriceSlider = (props) => {


  const handleSliderChange = (value) => {
    props.setMinPrice(value);
  };
  
  const getPriceLabel = (price) => {
    if (price === 0 || price == null) return "Any Price / week";
    return `Over ${price} / week`;
  };

  return (
    <Container>
      <Row className="mb-3">
        <Col>
          <Slider
            min={0}
            max={2000}
            step={200}
            value={ props.minPrice ?? 0}
            onChange={handleSliderChange}
            styles={{
              rail: {
                height: 6,
              },
              track: {
                height: 6,
              },
              handle: {
                borderWidth: 3,
                width: 22,
                height: 22,
                marginTop: -8,
              },
            }}
          />
          <div className="text-center">{getPriceLabel(props.minPrice)}</div>
        </Col>
      </Row>
    </Container>
  );
};

export default PriceSlider;
