import React from "react";
import Form from "react-bootstrap/Form";

function PingTypeSelector({ isRental, setIsRental }) {

  const handleSelectionChange = (event) => {
    if(event.target.id === "pm"){
      setIsRental(true);
    } else {
      setIsRental(false);
    }
  };

  return (
    <div className="mb-3">
      <Form.Check
        type="radio"
        id="res"
        label="Sales alerts"
        checked={!isRental}
        onChange={handleSelectionChange}
      />

      <Form.Check
        type="radio"
        id="pm"
        label="Rental alerts"
        checked={isRental}
        onChange={handleSelectionChange}
      />
    </div>
  );
}

export default PingTypeSelector;
